<template>
  <section id="news" class="section_container">
    <v-container>
      <default-home-section-title :title="titleSection" />
      <v-slide-group mobile-breakpoint="1000" center-active class="my-slider">
        <!-- <template v-slot:next>
          <v-icon color="red" x-large>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:prev>
          <v-icon color="red" x-large>mdi-chevron-left</v-icon>
        </template> -->
        <v-slide-item v-for="(item, index) in news" :key="index" class="">
          <v-card :color="cardColor" width="450" class="my-0 mx-2 flex-grow-1">
            <v-img height="350" :src="item.img"></v-img>
            <v-card-text>
              <p class="pl-1" style="color: white">
                {{ item.text }}
                <a
                  :href="item.link"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="section_link"
                >
                  <strong class="font-weight-normal" style="color: #efebe9"
                    >читать далее</strong
                  >
                </a>
              </p>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
      <!-- <v-row justify="space-between">
        <v-col class="d-flex col-md-4 col-xl-4 col-sm-12" v-for="item in news">
          <v-card :color="cardColor" elevation="24" class="flex-grow-1">
            <v-img height="250" :src="item.img"></v-img>
            <v-card-text>
              <p class="pl-5" style="color: white">
                {{ item.text }}
                <a :href="item.link" target="_blank" class="section_link">
                  <strong class="font-weight-normal" style="color: #efebe9"
                    >читать далее</strong
                  >
                </a>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
  </section>
</template>
<style lang="scss">
.section_container {
  width: 70%;
  margin: 0 auto;
  background-color: #fff;
}
.section_link {
  color: white;
  text-decoration: none;
}
.section_link:hover {
  text-decoration: underline;
}
.my-slider > .v-slide-group__next .theme--dark.v-icon,
.my-slider > .v-slide-group__prev .theme--dark.v-icon {
  color: rgb(234, 10, 10) !important;
}
.my-slider > .v-slide-group__wrapper > .v-slide-group__content {
  background-color: #ffffff !important;
}
</style>
<script>
export default {
  name: "HomeSectionDocs",
  components: {
    DefaultHomeSectionTitle: () => import("@/views/Home/HomeSectionTitle.vue"),
  },
  data() {
    return {
      titleSection: "Новости",
      // cardColor: "#5074b5",
      cardColor: "black",
      news: [
        {
          text: "В Казахстане в 2021 году введут в промышленную эксплуатацию систему учета нефти.",
          img: require("@/assets/img/news-01.jpeg"),
          link: "https://www.gov.kz/memleket/entities/energo/press/news/details/197888?lang=ru",
          icon: "mdi-download",
        },
        {
          text: "Систему учета нефти введут в промышленную эксплуатацию в 2021 году.​",
          img: require("@/assets/img/news-02.jpeg"),
          link: "https://kapital.kz/economic/95453/sistemu-ucheta-nefti-vvedut-v-promyshlennuyu-ekspluatatsiyu-v-2021-godu.html",
          icon: "mdi-download",
        },
        {
          text: "Министерство энергетики РК завершает работу над созданием информационной системы учета нефти и газового конденсата (ИСУН).",
          img: require("@/assets/img/news-03.jpeg"),
          link: "https://rus.azattyq-ruhy.kz/economics/23834-sistemu-ucheta-nefti-vvedut-v-ekspluatatsiiu-v-kazakhstane-v-2021-godu",
          icon: "mdi-download",
        },
      ],
    };
  },
};
</script>